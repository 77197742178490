import './set-public-path'
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import singleSpaVue from 'single-spa-vue';

Vue.config.productionTip = false;

Vue.use(Buefy)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
